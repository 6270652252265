export const initialState = {
  shoppingCart: {
    selectedOffer: 0,
    products: {},
    participants: {},
    marketingConditionsAccepted: false,
    marketingGroupConditionsAccepted: true,
    selectedAddOns: [],
    app18voucher: '',
    subTotal: 0,
    totalFee: 0,
    totalDDP: 0,
    total: 0,
    selectedArea: {},
  },
  submit: null,
  isPaymentFormSubmitted: false,
  paymentResult: {},
};

function updateCosts(products, addOns) {
  return Object.values(products).reduce(
    (costs, prod) => {
      let addOnsTotalCost = 0;
      if (addOns)
        addOnsTotalCost =
          Math.trunc(
            addOns.reduce((addOnsCosts, addOn) => {
              addOnsCosts += addOn.price * addOn.quantity;
              return addOnsCosts;
            }, 0) * 100
          ) / 100;
      costs.totalDDP +=
        (prod?.settings?.italyRegulation?.dirittoPrevendita || 0) * prod.bookingQuantity;
      costs.subTotal +=
        (prod.price.onlinePrice ?? prod.price.amount) * prod.bookingQuantity + addOnsTotalCost;
      costs.totalFee += (prod.commissions.transactionFee || 0) * prod.bookingQuantity;
      costs.total = costs.totalDDP + costs.subTotal + costs.totalFee;
      if (prod.onlinePaymentPercentage === 0) costs.total = 0;
      return costs;
    },
    {
      totalDDP: 0,
      subTotal: 0,
      totalFee: 0,
      total: 0,
    }
  );
}

export function reducer(state, action) {
  switch (action.type) {
    case 'addProduct': {
      const products = {
        [action.payload.id]: {
          ...state.shoppingCart.products[action.payload.id],
          ...action.payload,
        },
      };

      const costs = updateCosts(products, state.shoppingCart.selectedAddOns);

      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          products,
          selectedOffer: action.payload.id,
          ...costs,
        },
      };
    }
    case 'addParticipants': {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          participants: action.payload,
        },
      };
    }
    case 'addAddOns': {
      const costs = updateCosts(state.shoppingCart.products, action.payload);

      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          selectedAddOns: action.payload,
          ...costs,
        },
      };
    }
    case 'addBookingId': {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          bookingId: action.payload,
        },
      };
    }
    case 'emptyCart':
      return {
        ...state,
        shoppingCart: initialState.shoppingCart,
      };
    case 'changeQty': {
      const products = {
        ...state.shoppingCart.products,
        [action.payload.productId]: {
          ...state.shoppingCart.products[action.payload.productId],
          bookingQuantity: action.payload.newQty,
        },
      };
      const selectedAddOns = state.shoppingCart.selectedAddOns.map(addon => ({
        ...addon,
        quantity: action.payload.newQty,
      }));

      const costs = updateCosts(products, selectedAddOns);

      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          products,
          ...costs,
          selectedAddOns,
        },
      };
    }
    case 'setSubmit': {
      return {
        ...state,
        submit: action.payload,
      };
    }
    case 'setIsPaymentFormSubmitted': {
      return {
        ...state,
        isPaymentFormSubmitted: action.payload,
      };
    }
    case 'addPaymentResult': {
      return {
        ...state,
        paymentResult: {
          ...action.payload,
          bookingFormFields:
            action.payload.bookingFormFields &&
            action.payload.bookingFormFields.reduce(
              (list, field) => ({ ...list, [field.name]: field.value }),
              {}
            ),
        },
      };
    }
    case 'setSelectedArea': {
      return {
        ...state,

        shoppingCart: {
          ...state.shoppingCart,

          selectedArea: action.payload,
        },
      };
    }

    case 'removeSelectedArea': {
      return {
        ...state,

        shoppingCart: {
          ...state.shoppingCart,

          selectedArea: {},
        },
      };
    }

    case 'setMarketingConditionsAccepted': {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          marketingConditionsAccepted: action.payload,
        },
      };
    }
    case 'setMarketingGroupConditionsAccepted': {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          marketingGroupConditionsAccepted: action.payload,
        },
      };
    }
    case 'setApp18voucher': {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          app18voucher: action.payload,
        },
      };
    }
    case 'removeApp18voucher': {
      if (state?.shoppingCart?.app18voucher) {
        const shoppingCart = { ...state.shoppingCart };
        delete shoppingCart.app18voucher;
        return {
          ...state,
          shoppingCart,
        };
      }
      return { ...state };
    }
    case 'resetCheckout':
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
